import "./slider";
import accordion from './modules/accordion';

window.addEventListener('DOMContentLoaded', () => {
       
    accordion('.accordion-heading') 
});





//https://github.com/yankovalenko94/JS_practice
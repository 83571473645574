import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {
    $('.news_slider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<button class="prev news_arrow"><</button>',
        nextArrow: '<button class="next news_arrow">></button>',
        responsive: [{
            breakpoint: 1201,
            settings: {
                slidesToShow: 4,
                prevArrow: '<button class="prev news_arrow"><</button>',
                nextArrow: '<button class="next news_arrow">></button>',
                slidesToScroll: 1
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                prevArrow: '<button class="prev news_arrow"><</button>',
                nextArrow: '<button class="next news_arrow">></button>',
                slidesToScroll: 2
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                prevArrow: '<button class="prev news_arrow"><</button>',
                nextArrow: '<button class="next news_arrow">></button>',
                slidesToScroll: 2
            }
        }, {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                prevArrow: '<button class="prev arrow"><</button>',
                nextArrow: '<button class="next arrow">></button>',
                slidesToScroll: 1
            }
        }]
    });
    $('.topslide_slider').slick({        
        slidesToShow: 1,
        slidesToScroll: 1,        
        prevArrow: '<button class="prev arrow"><</button>',
        nextArrow: '<button class="next arrow">></button>',
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                prevArrow: '<button class="prev arrow"><</button>',
                nextArrow: '<button class="next arrow">></button>',
                slidesToScroll: 1
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                prevArrow: '<button class="prev arrow"></button>',
                nextArrow: '<button class="next arrow"></button>',
                slidesToScroll: 2
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                prevArrow: '<button class="prev arrow"></button>',
                nextArrow: '<button class="next arrow"></button>',
                slidesToScroll: 1
            }
        }]
    });
    $('.partners_slider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,        
        responsive: [{
            breakpoint: 1201,
            settings: {
                slidesToShow: 4,
                prevArrow: '<button class="prev news_arrow"><</button>',
                nextArrow: '<button class="next news_arrow">></button>',
                slidesToScroll: 1
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                prevArrow: '<button class="prev news_arrow"><</button>',
                nextArrow: '<button class="next news_arrow">></button>',
                slidesToScroll: 2
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                prevArrow: '<button class="prev news_arrow"><</button>',
                nextArrow: '<button class="next news_arrow">></button>',
                slidesToScroll: 2
            }
        }, {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                prevArrow: '<button class="prev arrow"><</button>',
                nextArrow: '<button class="next arrow">></button>',
                slidesToScroll: 1
            }
        }]
    });
});   